/** @jsx jsx */
import React, { useState, useContext } from "react"
import { jsx } from "theme-ui"
import { Link as GatsbyLink } from "gatsby"
import { push as Menu } from "react-burger-menu"
import { Box } from "@theme-ui/components"
import { TheSwitcher } from "."
import { MenuToggle } from "../components/MenuToggle"
import styled from "@emotion/styled"
import { motion } from "framer-motion"

const Nav = styled(motion.nav)`
  // position: absolute;
  // top: 0;
  // right: 50px;
  // z-index: 5501;
  position: absolute;
  right: 10px;
  top: 13px;
  z-index: 1103;
`

const ListLink = props => (
  <GatsbyLink
    to={props.to}
    sx={{
      fontFamily: "links",
      textTransform: "uppercase",
      letterSpacing: "wide",
      fontSize: "12px",
      fontWeight: "700",
      display: "block",
      py: 3,
      color: "navlinks",
      "&:hover": {
        "&:not([data-active='true'])": {
          opacity: 0.61,
        },
      },
      "&[data-active='true']": {
        color: "primary",
      },
    }}
    getProps={({ isPartiallyCurrent }) =>
      isPartiallyCurrent ? { "data-active": "true" } : null
    }
  >
    {props.children}
  </GatsbyLink>
)

// make a new context
const MyContext = React.createContext()

// create the provider
const MyProvider = props => {
  const [menuOpenState, setMenuOpenState] = useState(false)

  return (
    <MyContext.Provider
      value={{
        isMenuOpen: menuOpenState,
        toggleMenu: () => setMenuOpenState(!menuOpenState),
        stateChangeHandler: newState => setMenuOpenState(newState.isOpen),
      }}
      animate={menuOpenState ? "open" : "closed"}
    >
      {props.children}
    </MyContext.Provider>
  )
}

// create a button that calls a context function to set a new open state when clicked
const TheButton = () => {
  const ctx = useContext(MyContext)

  // const AltButton = styled.button`
  // .hamburger-inner,
  // .hamburger-inner::before,
  // .hamburger-inner::after {
  //   background-color: ${
  //     ctx.isMenuOpen ? theme.colors.primary : theme.colors.muted
  //   };
  //   }
  //   &.is-active {
  //     .hamburger-inner,
  // .hamburger-inner::before,
  // .hamburger-inner::after {
  //   background-color: ${theme.colors.muted}
  // }
  // `

  // TODO: REVIEW whether to use THEMEUI button comp or not

  // const [menuOpenState, setMenuOpenState] = useState(false)

  // const variants = {
  //   open: { opacity: 1, x: 0 },
  //   closed: { opacity: 1, x: "-5%" },
  // }

  return (
    <Nav initial={false} animate={ctx.isMenuOpen ? "open" : "closed"}>
      <MenuToggle
        toggle={ctx.toggleMenu}
        buttonClasses={`meatless-button ${
          ctx.isMenuOpen ? "is-active" : "nope"
        }`}
      />
    </Nav>
  )
}

// create a navigation component that wraps the burger menu
const Navigation = () => {
  const ctx = useContext(MyContext)

  const menuitems = [
    { label: "The Office Guys", href: "/the-office-guys/" },
    { label: "Articles", href: "/articles/" },
    { label: "Search Listings", href: "/search-listings/" },
  ]

  return (
    <Menu
      isOpen={ctx.isMenuOpen}
      onStateChange={state => ctx.stateChangeHandler(state)}
      outerContainerId={"tx-oc"}
      // pageWrapId={"tx-pg"}
      htmlClassName={"tx-menuopen"}
      customBurgerIcon={false}
      customCrossIcon={false}
      right
      disableAutoFocus
    >
      <Box>
        {menuitems.map((item, i) => (
          <ListLink key={i} to={item.href}>
            {item.label}
          </ListLink>
        ))}
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: "20px",
          left: "20px",
        }}
      >
        <TheSwitcher />
      </Box>
    </Menu>
  )
}

// default export here
export const SlideMenu = () => {
  return (
    <MyProvider>
      <div>
        <TheButton />
        <Navigation />
      </div>
    </MyProvider>
  )
}
