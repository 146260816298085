/** @jsx jsx */
import { jsx } from "theme-ui"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { mq, offset, offsetXxl, gutter } from "../styles/presets"
import styled from "@emotion/styled"

const StyledImage = styled(Img)`
  bottom: 33vh;
  left: ${gutter.default};
  right: ${gutter.default};
  top: ${gutter.default};

  ${mq.tablet} {
    bottom: 0;
    left: 0;
    right: auto;
    top: 0;
    width: ${offset};
  }

  ${mq.xxl} {
    width: ${offsetXxl};
  }
`

const CoverImage = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: { fields: { PartnerImage: { eq: "true" } } }) {
          edges {
            node {
              id
              fields {
                parentId
                title
                slug
                uri
              }
              relativePath
              name
              childImageSharp {
                fluid(
                  maxWidth: 800
                  quality: 80
                  duotone: { highlight: "#ffffff", shadow: "#663399" }
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename)
      })
      if (!image) {
        return null
      }

      const imageSizes = image.node.childImageSharp.fluid
      return (
        <StyledImage
          alt={props.alt}
          fluid={imageSizes}
          style={{ position: `fixed` }}
          backgroundColor={
            props.imageBackgroundColor ? props.imageBackgroundColor : false
          }
        />
      )
    }}
  />
)

export default CoverImage
