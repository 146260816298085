/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "@emotion/styled"
import { motion } from "framer-motion"
import { LinkWrapper, TheImage } from "."
import { Grid, Heading } from "@theme-ui/components"

const StyledDiv = styled(motion.div)``

const thelist = {
  hidden: {
    opacity: 1,
    scale: 0.75,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
}

const theitem = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    color: "currentColor",
    // color: theme.colors.primary_fg,
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeInOut",
    },
  },
  active: {
    color: "var(--tx_highlight)",
    scale: 1.065,
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeInOut",
    },
  },
}

export const ArticleList = ({ items }) => (
  <StyledDiv
    initial="hidden"
    animate="visible"
    variants={thelist}
    sx={{
      // bg: 'icon_red',
      // p: 4,
      display: "grid",
      // gridGap: 4,
      // gridColumnGap: 8,
      gridRowGap: 2,
      gridTemplateColumns: "1fr",
    }}
  >
    {items.map((item, i) => (
      <Item {...item} key={i} />
    ))}
  </StyledDiv>
)

const Item = ({ title, body, featuredImage, uri, i }) => (
  <motion.div
    key={i}
    whileHover="active"
    sx={{
      // bg: "fg_low",
      position: "relative",
      px: 0,
      pb: 4,
      mb: 4,
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: "fg_low",
      cursor: "pointer",
      color: "text",
      "&:hover": {
        color: "highlight",
      },
    }}
    variants={theitem}
  >
    <Grid
      sx={{
        gridTemplateColumns: ["1fr", "1fr 2fr"],
      }}
    >
      <TheImage imageObject={featuredImage[0]} w={240} h={150} variant="bio" />

      <Heading
        sx={{
          fontWeight: 500,
          fontSize: [3, 4, 5],
          lineHeight: 1.1618,
        }}
        variants={theitem}
      >
        {title}
      </Heading>
    </Grid>
    <LinkWrapper to={`/${uri}/`}>Continue Reading</LinkWrapper>
  </motion.div>
)
