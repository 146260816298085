/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui"
import { motion } from "framer-motion"
import { Button } from "@theme-ui/components"
import { FiMoon, FiSun } from "react-icons/fi"
import useBodyClass from "../hooks/useBodyClass"

export const TheSwitcher = ({ props }) => {
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`
  const toggleColorMode = e => {
    setColorMode(isDark ? `light` : `dark`)
  }
  useBodyClass(`tx-${colorMode}`)

  const MotionButton = motion.custom(Button)

  return (
    <MotionButton
      variant="themeswitch"
      {...props}
      onClick={toggleColorMode}
      whileHover={{ scale: 1.15 }}
      whileTap={{ scale: 0.95 }}
    >
      {isDark ? (
        <FiMoon size="1.2em" />
      ) : (
        <FiSun
          size="1.2em"
          sx={{
            color: "var(--navlinks)",
            fill: "var(--navlinks)",
          }}
        />
      )}
    </MotionButton>
  )
}
