/** @jsx jsx */
import CoverImage from "./CoverImage"
import styled from "@emotion/styled"
import { TheHeader, SlideMenu } from "."
import { jsx, useColorMode, Styled } from "theme-ui"
import Global from "../styles/globals"
import SEO from "./seo"
import { mq, elevation, offset, offsetXxl, gutter } from "../styles/presets"
import useBodyClass from "../hooks/useBodyClass"

const Container = styled(`div`)`
  margin-top: calc(67vh - ${gutter.default});
  padding: ${gutter.default};
  position: relative;

  ${mq.tablet} {
    margin-left: ${offset};
    margin-top: 0;
    // max-width: 37.5rem;
    padding: ${gutter.tablet};
    position: relative;
  }

  ${mq.desktop} {
    padding: ${gutter.desktop};
    padding-top: ${gutter.tablet};
  }

  ${mq.xxl} {
    margin-left: ${offsetXxl};
  }
`
const Main = styled(`main`)`
  padding-top: ${gutter.default};
  position: relative;
  z-index: ${elevation.overlay};
`

export const FixedLayout = ({
  children,
  image,
  imageTitle,
  imageBackgroundColor,
  ...props
}) => {
  const [colorMode] = useColorMode()
  useBodyClass(`tx-fixedpg`)
  useBodyClass(`tx-${colorMode}`)

  return (
    <Styled.root
      id="tx-oc"
      data-headertype={props.headerVariant}
      sx={{ backgroundColor: "background" }}
    >
      <Global />
      <SEO title={props.seoTitle} />
      <SlideMenu />
      <TheHeader siteTitle="Office Space Guys" variant="fixedpage" />
      <Container>
        {image && (
          <CoverImage
            filename={image}
            backgroundColor={
              imageBackgroundColor ? imageBackgroundColor : false
            }
            title={imageTitle}
          />
        )}
        <Main>{children}</Main>
      </Container>
    </Styled.root>
  )
}
