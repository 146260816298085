/** @jsx jsx */
import { TheHeader, TheFooter, Container, SlideMenu } from "."
import { Layout, jsx, useColorMode, Main, Styled } from "theme-ui"
import Global from "../styles/globals"
import { useMedia } from "react-use"
import SEO from "../components/seo"

export const TheLayout = ({ children, ...props }) => {
  const [colorMode] = useColorMode()
  const isWide = useMedia("(min-width: 992px)")

  return (
    <Styled.root id="tx-oc" data-headertype={props.headerVariant}>
      <Global />
      <SEO title={props.seoTitle} />
      {!isWide && <SlideMenu />}
      <Layout
        {...props}
        id="tx-pg"
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
        data-colormode={colorMode}
      >
        <TheHeader
          siteTitle="Office Space Guys"
          variant={props.headerVariant}
        />
        <Main
          sx={{
            display: "flex",
            flex: "1 1 auto",
            px: 3,
            pt: [3, 4, 5],
            pb: 3,
          }}
        >
          <Container unconfined={props.unconfined}>{children}</Container>
        </Main>
        <TheFooter />
      </Layout>
    </Styled.root>
  )
}
