import React from "react"
import styled from "@emotion/styled"
import { Image } from "@theme-ui/components"
import Fade from "react-reveal/Fade"
import LazyLoad from "react-lazy-load"

export const TheImage = props => {
  const w =
    props.w ||
    (props.thumb && "450") ||
    (props.medium && "800") ||
    (props.large && "912") ||
    (props.hero && "1312") ||
    "912"

  const h = props.h ? props.h : props.square ? w : w * 0.5625

  const calculatedRatio = (h / w) * 100

  const imgixSize = `&w=${w}&h=${h}`

  const fpx = props.imageObject ? props.imageObject.focalPoint.x : ".4"
  const fpy = props.imageObject ? props.imageObject.focalPoint.y : ".4"
  // const fpz = props.customParams
  // 	? props.customParams.focalPointZoom
  // 	: '1'

  const min = 1
  const max = 500
  const random = min + Math.random() * (max - min)

  const key = props.imageObject ? props.imageObject.id : random

  const image = props.imageObject
    ? `${props.imageObject.url +
        props.imgixParams +
        imgixSize}&auto=compress,format&fp-x=${fpx}&fp-y=${fpy}`
    : props.image

  const ImageWrapper = styled.div`
    flex: none;
    // background: yellow;

    .LazyLoad {
      /* height: 225px;
			background: pink; */
      width: 100%;
      position: relative;
      background: #eee;
      -webkit-transition: background 0.5s;
      transition: background 0.5s;
      overflow: hidden;
      object-fit: cover;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      /* padding-bottom: 50%; */
      padding-top: ${props.altRatio
        ? props.altRatio
        : props.square
        ? "100"
        : calculatedRatio}%;
    }
    .LazyLoad.is-visible {
      /* height: auto; */
      /* height: 225px; */
      /* background: none !important; */
      background: none;
    }

    .LazyLoad img {
      position: absolute !important;
      width: 100%;
      /* height: auto; */
      left: 0;
      top: 0;
      /* opacity: 0; */
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
      -webkit-transition: opacity 0.4s, -webkit-transform 0.6s;
      transition: opacity 0.4s, -webkit-transform 0.6s;
      transition: opacity 0.4s, transform 0.6s;
      transition: opacity 0.4s, transform 0.6s, -webkit-transform 0.6s;
    }

    .LazyLoad.is-visible img {
      /* opacity: 1 !important; */
      transform: scale(1) !important;
    }
  `

  return (
    image && [
      <Fade key={key}>
        <ImageWrapper className={props.fullBleed ? "fullBleed" : null}>
          <LazyLoad offsetVertical={300} className={props.lazyClasses}>
            <Image
              width={w || 1}
              src={image}
              borderRadius={0}
              m={0}
              p={0}
              className={props.className}
              css={{ display: "block" }}
              alt={key}
              {...props}
            />
          </LazyLoad>
        </ImageWrapper>
      </Fade>,
    ]
  )
}

TheImage.defaultProps = {
  image:
    "https://images.unsplash.com/photo-1553018491-b388f7c70c1e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80",
  imgixParams: "?crop=focalpoint&fit=crop&auto=compress,format",
}

// + '&fp-z=' + fpz
