import React, { useState } from "react"
// import { useLocalStorage } from "react-use"
import axios from "axios"
import { navigate } from "gatsby"
import { locations } from "../data/locations"
import { motion } from "framer-motion"
import {
  Box,
  Button,
  Heading,
  Label,
  Input,
  Select,
} from "@theme-ui/components"

export const FindSpace = ({
  title,
  notes,
  locationpreference,
  email,
  phone,
  spaceneeded,
}) => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [details, setDetails] = useState({
    name: title || "",
    notes: notes || "",
    email: email || "",
    phone: phone || "",
    spaceneeded: spaceneeded || "",
    locationpreference: locationpreference || "",
  })
  const [errors, setErrors] = useState({
    name: "",
    notes: "",
    email: "",
    phone: "",
    spaceneeded: "",
    locationpreference: "",
  })

  const handleChange = e =>
    setDetails({ ...details, [e.target.name]: e.target.value })

  const handleBlur = e => {
    if (!e.target.value) {
      setErrors({ ...errors, [e.target.name]: "Required field" })
    } else {
      setErrors({ ...errors, [e.target.name]: "" })
    }
  }

  let axiosConfig = {
    headers: {
      Authorization: `Bearer ${process.env.GATSBY_AIRTABLE_API_KEY}`,
      "Content-Type": "application/json",
    },
  }

  const handleSubmit = async e => {
    try {
      e.preventDefault()
      setSubmitting(true)
      const {
        name,
        notes,
        email,
        phone,
        spaceneeded,
        locationpreference,
      } = details
      //   const locationpreference = window.localStorage.getItem(
      //     "interestedNeighborhoods"
      //   )

      if (!name || !email || !phone) {
        alert("All fields are required")
        setSubmitting(false)
      } else {
        await axios.post(
          "https://api.airtable.com/v0/appHONjTFFqaTB1pu/Formtest",
          {
            fields: {
              name,
              notes,
              email,
              phone,
              spaceneeded,
              locationpreference,
            },
          },
          axiosConfig
        )
        localStorage.setItem("formSubmitted", true)
        // window.localStorage.setItem("formSubmitted", true)
        // window.localStorage.setItem("interestedNeighborhoods", [
        //   "sample one",
        //   "sample other one",
        // ])
        navigate("/success/")
        setSubmitting(false)
      }
    } catch (err) {
      console.log(err)
      console.log(err.request)
      setSubmitting(false)
    }
  }
  return (
    <Box
      as="form"
      p={4}
      color="text"
      bg="fg_min"
      mb={4}
      onSubmit={handleSubmit}
    >
      <Heading
        mb={4}
        sx={{
          fontSize: [4, 5],
          fontWeight: 500,
        }}
      >
        Search Our Listings
      </Heading>

      <Box>
        <Label htmlFor="spaceneeded">Space Needed</Label>
        <Select
          name="spaceneeded"
          value={details.spaceneeded}
          onChange={handleChange}
          mb={4}
        >
          <option value="under1k">Less than 1,000 Sq Ft</option>
          <option value="1k3k">1,000-3,000 Sq Ft</option>
          <option value="3k5k">3,000-5,000 Sq Ft</option>
          <option value="5k">5,000+ Sq Ft</option>
          <option value="10k">10,000+ Sq Ft</option>
          <option value="notsure">Not sure</option>
        </Select>
      </Box>

      <Box>
        <Label htmlFor="locationpreference">Location Preference</Label>
        <Select
          name="locationpreference"
          mb={4}
          value={details.locationpreference}
          onChange={handleChange}
        >
          {locations.map((location, i) => {
            return (
              <option key={i} value={location.label}>
                {location.label}
              </option>
            )
          })}
        </Select>
      </Box>

      <Box>
        <Label htmlFor="name">Name</Label>
        <Input
          type="text"
          name="name"
          value={details.name}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter your name"
          mb={4}
        />
        {errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
      </Box>

      <Box>
        <Label htmlFor="email">Email</Label>
        <Input
          type="email"
          name="email"
          value={details.email}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter your email"
          mb={4}
        />
        {errors.email && <span style={{ color: "red" }}>{errors.email}</span>}
      </Box>

      <Box>
        <Label htmlFor="phone">Phone</Label>
        <Input
          type="text"
          name="phone"
          value={details.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter some phone"
          mb={4}
        />
        {errors.phone && <span style={{ color: "red" }}>{errors.phone}</span>}
      </Box>

      <Box>
        <Label htmlFor="notes">Notes</Label>
        <Input
          type="text"
          name="notes"
          value={details.notes}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Enter some notes"
          mb={4}
        />
        {errors.notes && <span style={{ color: "red" }}>{errors.notes}</span>}
      </Box>

      <Button disabled={isSubmitting} type="submit">
        Submit
      </Button>
    </Box>
  )
}
