import React from "react"
import { Global } from "@emotion/core"

export default props => (
  <Global
    styles={`
    :root {
      --text: #D1D2D3;
      --background: #fff;
      --muted: #f6f6f6;
      --darkbg: #262626;
      --primary: #20bacf;
      // --primary: hsl(187.2, 73.22%, 46.86%);
      --navlinks: #333;
      --navlinkshover: hsl(187.2, 73.22%, 46.86%);
      --font-body: "Sentinel A, Sentinel B";
      --font-links: "Gotham SSm A,Gotham SSm B";

      --dk_tx_primary_fg: #D1D2D3;
      --dk_tx_primary_bg: #1A1D21;
      --dk_tx_inverted_fg: #1A1D21;
      --dk_tx_inverted_bg: #D1D2D3;
      --dk_tx_fg_max: #9A9C9E;
      --dk_tx_fg_high: #75777A;
      --dk_tx_fg_low: #323538;
      --dk_tx_fg_min: #212428;
      --dk_tx_highlight: #20bacf;
      --dk_tx_highlight_hover: #41cfe1;
      --dk_tx_highlight_accent: #1993a3;
      --dk_tx_secondary_highlight: rgba(242, 199, 68, 1);

      --tx_primary_fg: #1D1C1D;
      --tx_primary_bg: #FFF;
      --tx_inverted_fg: #FFF;
      --tx_inverted_bg: #1D1C1D;
      --tx_fg_max: #616061;
      --tx_fg_high: #868686;
      --tx_fg_low: #DDD;
      --tx_fg_min: #F8F8F8;
      --tx_highlight: #20bacf;
      --tx_highlight_hover: #41cfe1;
      --tx_highlight_accent: #1993a3;
      --tx_secondary_highlight: rgba(242, 199, 68, 1);

      --heading-height: 6rem;
      // --heading-height: 8.2rem;
      --global-border-width: 12px;
      @media (min-width: 540px) {
        --global-border-width: 24px;
        --heading-height: 8.2rem;
        // --heading-height: 96px;
      }
    }
    :focus {       
        outline-color: var(--theme-ui-colors-muted);
        outline-style: none;
        outline-width: 0;
    }
    ::selection { 
      background: var(--tx_secondary_highlight); 
    }
    body.tx-dark {
      --text: #1D1C1D;
      --navlinks: #fff;
      --tx_primary_fg: #D1D2D3;
      --tx_primary_bg: #1A1D21;
      --tx_inverted_fg: #1A1D21;
      --tx_inverted_bg: #D1D2D3;
      --tx_fg_max: #9A9C9E;
      --tx_fg_high: #75777A;
      --tx_fg_low: #323538;
      --tx_fg_min: #212428;
      --tx_highlight: #20bacf;
      --tx_highlight_hover: #41cfe1;
      --tx_highlight_accent: #1993a3;
      --tx_secondary_highlight: rgba(242, 199, 68, 1);
    }
    [data-headertype='transparent'] header, [data-headertype='transparent'] button.hamburger, [data-headertype='transparent']  .meatless-button  {
      --navlinks: white;
    }
    [data-headertype='transparent'] button.hamburger.is-active, .meatless-button.is-active {
      --navlinks: var(--tx_primary_fg);
    } 
    [data-headertype='fixedpage'] header {
      --navlinks: #333;
    }
      html {
        font-size: 62.5%;
        line-height: 2;
      }
      html.tx-menuopen {
       overflow: hidden;
      }
      body {
        margin: 0;
        font-size: 1.6rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        &:not(.tx-fixedpg) {
          background: var(--primary);
        }
        &.tx-fixedpg {
          background: var(--tx_primary_bg);
        }
        
        @media (min-width: 40em) {
          body {
              font-size:1.8rem
          }
      }
      


      .image-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 66.66%;
        cursor: zoom-in;
        margin: 50px 0;
      }
      
      .image-container.open {
        cursor: zoom-out;
      }
      
      .image-container .shade {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        opacity: 0;
        background: rgba(0, 0, 0, 0.9);
      }
      
      .image-container.open .shade {
        pointer-events: auto;
        opacity: 1;
      }
      
      .image-container img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
      
      .image-container.open img {
        position: fixed;
        width: auto;
        height: auto;
        max-width: 100%;
        margin: auto;
      }

      .modal-shade,
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal-shade {
  background: rgba(0, 0, 0, 0.9);
  will-change: opacity;
  z-index: 2;
}

.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 3;
}

.modal {
  background: white;
  border-radius: 20px;
  width: 400px;
  height: 350px;
}

// BURGER MENU HERE
.tx-menuopen {
  overflow: hidden;
}
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}
.bm-menu {
  background: var(--tx_primary_bg); // #f6f6f6;
  padding: 3.25em 1.5em 0;
  font-size: 1.15em;
}
.bm-item {
  display: inline-block;
}
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}


      }`}
  />
)
