//TODO: remove styles from here @today
/** @jsx jsx */
import { Link as GatsbyLink } from "gatsby"
import { jsx } from "theme-ui"
import { Flex } from "@theme-ui/components"
import { motion } from "framer-motion"
import { LogoLockup, TheLink, TheSwitcher } from "."
import { useMedia } from "react-use"

const ListLink = props => (
  <GatsbyLink
    to={props.to}
    sx={{
      variant: "links.nav",
    }}
    getProps={({ isPartiallyCurrent }) =>
      isPartiallyCurrent ? { "data-active": "true" } : null
    }
  >
    {props.children}
  </GatsbyLink>
)

export const TheHeader = ({ variant = "default", ...props }) => {
  const isWide = useMedia("(min-width: 992px)")

  const menuitems = [
    { label: "The Office Guys", href: "/the-office-guys/" },
    { label: "Articles", href: "/articles/" },
    { label: "Search Listings", href: "/search-listings/" },
  ]

  return (
    <header
      sx={{
        variant: `layout.header.${variant}`,
        height: "var(--heading-height)",
      }}
      data-headertype={variant}
    >
      <TheLink to="/" sx={{ color: "heading", px: 3, height: "41px" }}>
        <motion.div
          whileHover={{ scale: 1.065 }}
          whileTap={{ scale: 0.97 }}
          transition={{ duration: 0.365 }}
        >
          <LogoLockup w={isWide ? "300" : "240"} />

          <span className="sr-only" sx={{ display: "none" }}>
            {props.siteTitle}
          </span>
        </motion.div>
      </TheLink>
      <Flex
        className="headerlinks"
        sx={{
          alignItems: "center",
          variant: "styles.Navlinks",
          position: "relative",
          display: "flex",
          "@media screen and (max-width: 992px)": {
            display: "none",
          },
        }}
      >
        {menuitems.map((item, i) => (
          <ListLink key={i} to={item.href}>
            {item.label}
          </ListLink>
        ))}

        <TheSwitcher />
      </Flex>
    </header>
  )
}
